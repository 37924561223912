import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'react-public-ip'
import { useDebouncedEffect } from '@/helpers'

const defaultBankingInfo = {
    agency: '',
    account: '',
    digit: ''
}

const defaultAccountManagerInfo = {
    name: 'Condolivre',
    description: 'Gerente de conta',
    phone: '(11) 3097-9918',
    imageUrl: '/images/person.png'
}

const defaultUserInfo = {
    name: '',
    email: '',
    first_login: false
}

const defaultUserBalance = ''
const defaultRoles = []

const defaultAudit = {
    ip_address: '0.0.0.0',
    fingerprint: '',
    lat: '0',
    long: '0'
}

const DigitalAccountUserContext = createContext({
    userInfo: defaultUserInfo,
    userBalance: defaultUserBalance,
    userRoles: defaultRoles,
    bankingInfo: defaultBankingInfo,
    accountManagerInfo: defaultAccountManagerInfo,
    setUserInfo: () => {},
    setUserBalance: () => {},
    setBankingInfo: () => {},
    deleteUserData: () => {},
    setAccountManagerInfo: () => {},
    isAuthenticated: false,
    setAuthenticated: () => {},
    audit: defaultAudit,
    setFirstLogin: () => {}
})

export const useDigitalAccountUser = () => useContext(DigitalAccountUserContext)

export const DigitalAccountUserProvider = ({ children, authenticated }) => {
    const [isAuthenticated, setAuthenticated] = React.useState(authenticated)
    const [digitalAccountAuthenticated, setDigitalAccountAuthenticated] = useState({})
    const [userInfo, setUserInfoState] = useState(defaultUserInfo)
    const [userRoles, setUserRoles] = useState(defaultRoles)
    const [userBalance, setUserBalanceState] = useState(defaultUserBalance)
    const [bankingInfo, setBankingInfoState] = useState(defaultBankingInfo)
    const [accountManagerInfo, setAccountManagerInfoState] = useState(defaultAccountManagerInfo)

    useEffect(() => {
        const digitalAccountSrc = localStorage.getItem('digitalAccountUser')

        if (digitalAccountSrc) {
            setDigitalAccountAuthenticated(JSON.parse(digitalAccountSrc))
            setUserInfoState(JSON.parse(digitalAccountSrc).userInfo || defaultUserInfo)
            setUserBalanceState(JSON.parse(digitalAccountSrc).userBalance || defaultUserBalance)
            setBankingInfoState(JSON.parse(digitalAccountSrc).bankingInfo || defaultBankingInfo)
            setAccountManagerInfoState(
                JSON.parse(digitalAccountSrc).accountManagerInfo || defaultAccountManagerInfo
            )
            setUserRoles(JSON.parse(digitalAccountSrc).userRoles || defaultRoles)
        }
    }, [])

    useDebouncedEffect(
        () => {
            localStorage.setItem(
                'digitalAccountUser',
                JSON.stringify({
                    userInfo,
                    userBalance,
                    bankingInfo,
                    accountManagerInfo,
                    userRoles
                })
            )
        },
        [userInfo, userBalance, bankingInfo, accountManagerInfo],
        600
    )

    const setAccountManagerInfo = (newData) => {
        setAccountManagerInfoState(newData)
        setDigitalAccountAuthenticated({
            ...digitalAccountAuthenticated,
            accountManagerInfo: newData
        })
    }

    const setUserInfo = (newData) => {
        setUserInfoState(newData)
        setDigitalAccountAuthenticated({
            ...digitalAccountAuthenticated,
            userInfo: newData
        })
    }

    const setUserBalance = (newBalance) => {
        setUserBalanceState(newBalance)
        setDigitalAccountAuthenticated({
            ...digitalAccountAuthenticated,
            userBalance: newBalance
        })
    }

    const setBankingInfo = (newBankingInfo) => {
        setBankingInfoState(newBankingInfo)
        setDigitalAccountAuthenticated({
            ...digitalAccountAuthenticated,
            bankingInfo: newBankingInfo
        })
    }

    const deleteUserData = () => {
        setUserInfo(defaultUserInfo)
        setUserBalance(defaultUserBalance)
        setBankingInfo(defaultBankingInfo)
        setAccountManagerInfo(defaultAccountManagerInfo)
        setUserRoles(defaultRoles)
    }

    const setFirstLogin = (value) => {
        setUserInfoState((prevUserInfo) => ({
            ...prevUserInfo,
            first_login: value
        }))
    }

    const [audit, setAudit] = useState({})
    const [baseAudit, setBaseAudit] = useState({
        ip_address: '0.0.0.0',
        fingerprint: window.navigator.userAgent
    })
    const [geoLocation, setGeoLocation] = useState({})

    useEffect(async () => {
        setAudit({
            ...baseAudit,
            lat: geoLocation.lat || '0',
            long: geoLocation.long || '0'
        })
    }, [geoLocation, baseAudit])

    useEffect(async () => {
        navigator.geolocation.getCurrentPosition((res) => {
            setGeoLocation({
                lat: res.coords.latitude.toString(),
                long: res.coords.longitude.toString()
            })
        })

        setBaseAudit({
            ...baseAudit,
            fingerprint: window.navigator.userAgent,
            ip_address: await v4()
        })
    }, [])

    const dataValues = {
        userInfo,
        userBalance,
        userRoles,
        bankingInfo,
        accountManagerInfo,
        setUserInfo,
        setUserBalance,
        setBankingInfo,
        setFirstLogin,
        deleteUserData,
        setAccountManagerInfo,
        audit,
        setUserRoles
    }

    return (
        <DigitalAccountUserContext.Provider
            value={{
                ...dataValues,
                isAuthenticated,
                setAuthenticated
            }}>
            {children}
        </DigitalAccountUserContext.Provider>
    )
}

DigitalAccountUserProvider.propTypes = {
    children: PropTypes.node,
    authenticated: PropTypes.bool
}

export default DigitalAccountUserContext

export function useDigitalAccountAuth() {
    const context = React.useContext(DigitalAccountUserContext)
    if (context === undefined) {
        throw new Error('useDigitalAccountAuth must be used within an DigitalAccountUserProvider')
    }
    return context
}

export function useDigitalAccountIsAuthenticated() {
    const context = useDigitalAccountAuth()
    return context.isAuthenticated
}
